import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CategoryContainer, BarContainer, ProgressBar,
  ProgressContent, ProgressPoints, RewardText, ArrowContainer, ArrowReward, RewardLevel, 
  ProgressCard, Border, Layout, RightArrow} from './styles';

import { imgPath } from '../../utils/constants';
import { commaSeperator } from '../../utils/methods';
import { Waiting } from '../../components';
import{ getUserRewardDetails } from '../../redux/actions';
import RewardsCategory from './RewardsCategory';
import {withTranslation} from 'react-i18next';

class Rewards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstIndex: 0,
      secondIndex: 0
    }
  }

  componentDidMount() {
    this.setState({
      firstIndex: 10,
      secondIndex: 5,
    });
    const { getUserRewardDetails } = this.props;
    getUserRewardDetails();
  }

  render() {
    const { firstIndex, secondIndex } = this.state;
    const { reward, fitnessDetails, socialDetails, wellnessDetails, educationDetails, bonusWellness, bonusSocial, bonusEducation, bonusFitness,t} = this.props;
    if(!reward) {
      return <Waiting />
    }
    let silverReachPoint = 3501;
    let goldReachPoint = 7001;
    let bronzeReachPoint = 1000;

    const categoryArray = [
      {
        src:"fitness-reward",
        title: 'Fitness Points',
        availalePoint: reward.max_fitness,
        earnPoint: reward.fitness_point,
        percentage: reward.fitness_percentage,
        maxAvailableFitnessPoint: reward.max_fitness_points || 0,
        details: fitnessDetails || [],
        bonus: bonusFitness,
      },
      {
        src: 'social-reward',
        title: 'Social Points',
        availalePoint: reward.max_social,
        earnPoint: reward.social_point,
        percentage: reward.social_percentage,
        details: socialDetails || [],
        bonus: bonusSocial,
      },
      {
        src: 'wellness-reward',
        title: 'Health Assessment Points',
        availalePoint: reward.max_wellness,
        earnPoint: reward.wellness_point,
        percentage: reward.wellness_percentage,
        details: wellnessDetails || [],
        bonus: bonusWellness,
      },
      {
        src: 'education-reward',
        title: 'Wellness Points',
        availalePoint: reward.max_education,
        earnPoint: reward.education_point,
        percentage: reward.education_percentage,
        details: educationDetails || [],
        bonus: bonusEducation,
      }
    ]
    let progressData = {};
    progressData.point = reward.total > 10000 ? 0 : reward.total >= goldReachPoint ? 10000-reward.total : reward.total >= silverReachPoint ? goldReachPoint-reward.total : reward.total < bronzeReachPoint ? bronzeReachPoint-reward.total : silverReachPoint-reward.total;
    progressData.level = reward.total > 10000 ? '' : reward.total < bronzeReachPoint ? 'bronze' : reward.total < silverReachPoint ? 'silver' :  reward.total < goldReachPoint ? 'gold' :  '';
    return (
      <div>
        <Layout>
          <ProgressCard >
            <ProgressContent>
              { reward.total >= 1000 &&
              <div className="imageContainer">
                <img src={reward.total > 7000 ? `${imgPath}/Rewards/gold.png` : reward.total > 3500 ? `${imgPath}/Rewards/silver.png` : `${imgPath}/Rewards/bronze.png`}/>
              </div>
              }
              <ProgressPoints>
                <div className="points">{commaSeperator(reward.total)}</div>
                <div className="totalPoint">{t("total points")}</div>
                <div className="pointContent">{ reward.total < 7001 && <div className="inline"> <span>{commaSeperator(progressData.point)}</span> {progressData.point > 1 ? 'points' : 'point'} left to reach <span>{progressData.level}</span></div> }</div>
              </ProgressPoints>
            </ProgressContent>
            <Border />
            <div className="padding">
              <ProgressBar width={reward.total <= 10000 ? reward.total*100/10000 : 100} color={reward.total > 7000 ? "#DCC34C" : reward.total > 3500 ? "#B9C1C6" : "#A57576"}>
                <div className="progrss" />
              </ProgressBar>
              <RewardLevel bgColor="#A57576" borderLeftRadius={1} zIndex={firstIndex}>
                <BarContainer>
                  <div className="flexBox">
                    <RewardText>
                      <img src={`${imgPath}/Rewards/bronze.png`} />
                      <div className={"details"}>
                        <div className="text">{t('Bronze')}</div>
                        <div className="levelPoint">
                            1,000 - 3,499 {t('pts')}
                        </div>
                      </div>
                    </RewardText>
                  </div>
                </BarContainer>
              </RewardLevel>
              <RewardLevel bgColor="#B9C1C6" zIndex={secondIndex}>
                <ArrowContainer>
                  <ArrowReward bgColor="#A57576"/>
                </ArrowContainer>
                <BarContainer customAlign={1}>
                  <div className="flexBox">
                    <RewardText>
                      <img src={`${imgPath}/Rewards/silver.png`} />
                      <div className={"details"}>
                        <div className="text">{t('Silver')}</div>
                        <div className="levelPoint">
                            3,500 - 6,999 {t('pts')}
                        </div>
                      </div>
                    </RewardText>
                  </div>
                </BarContainer>
              </RewardLevel>
              <RewardLevel bgColor="#DCC34C" borderRightRadius={0}>
                <ArrowContainer>
                  <ArrowReward bgColor="#B9C1C6"/>
                </ArrowContainer>
                <BarContainer customAlign={1}>
                  <div className="flexBox">
                    <RewardText>
                      <img src={`${imgPath}/Rewards/gold.png`} />
                      <div className={"details"}>
                        <div className="text">{t('Gold')}</div>
                        <div className="levelPoint">
                            7,000 - 10,000 {t('pts')}
                        </div>
                      </div>
                    </RewardText>
                  </div>
                </BarContainer>
                <RightArrow />
              </RewardLevel>
            </div>
          </ProgressCard>
        </Layout>
        <CategoryContainer>
          { categoryArray.map((category, index) => (
            <RewardsCategory category={category} key={index} history={this.props.history}/>
          ))}
        </CategoryContainer>
      </div>
    );
  }
}

Rewards.propTypes = {
  reward: PropTypes.object,
  monthlyRewardPoint: PropTypes.number,
  fitnessDetails: PropTypes.array,
  socialDetails: PropTypes.array,
  wellnessDetails: PropTypes.array,
  educationDetails: PropTypes.array,
  bonusWellness: PropTypes.array,
  bonusSocial: PropTypes.array,
  getUserRewardDetails: PropTypes.func,
  history: PropTypes.object,
  bonusFitness: PropTypes.array,
  bonusEducation: PropTypes.array,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  reward: state.wellnessDashboard.reward,
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  fitnessDetails: state.wellnessDashboard.fitnessDetails,
  socialDetails: state.wellnessDashboard.socialDetails,
  wellnessDetails: state.wellnessDashboard.wellnessDetails,
  educationDetails: state.wellnessDashboard.educationDetails,
  bonusWellness: state.wellnessDashboard.bonusWellness,
  bonusSocial: state.wellnessDashboard.bonusSocial,
  bonusFitness: state.wellnessDashboard.bonusFitness,
  bonusEducation: state.wellnessDashboard.bonusEducation
});

const mapDispathcToProps = (dispatch) => ({
  getUserRewardDetails: () => dispatch(getUserRewardDetails())
})

export default connect(mapStateToProps, mapDispathcToProps)(withTranslation()(Rewards));