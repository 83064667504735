import React from  'react';
import PropTypes from 'prop-types';
import { ProgressPoints, CategoryCardV2, ImageContainer, PointsCard} from './styles';
import { imgPath, ImageUrl } from '../../utils/constants';
import { commaSeperator } from '../../utils/methods';
import ImageThumbnail from "../../components/ImageThumbnail";
import {withTranslation} from 'react-i18next';
class RewardsCaregory extends React.Component {

  redirection = (data) =>{
    const { history } = this.props;
    history.push(data.redirection === 'event' ? "/events" : data.redirection === 'challenge' ? "/challenges" : null)
  }

  render() {
    const { category , t} = this.props;
    return (
      <CategoryCardV2>
        <ImageContainer>
          <ImageThumbnail src={`${imgPath}/Rewards/${category.src}.png`} alt={"slide"}/>
        </ImageContainer>
        <div className="bg-opacity" />
        <div className="textContainer">
          <div className="rewardName">
            {category.title}
          </div>
          <ProgressPoints padding={1}>
            <div className="points">
              {commaSeperator(category.title === 'Fitness Points' ? (category.maxAvailableFitnessPoint) : 
                (category.earnPoint && category.earnPoint || 0))}
              <span className="totalPoint">{t('pts')}</span>
            </div>
          </ProgressPoints>
          <div className="available">
            <div>{`${commaSeperator(category.availalePoint && category.availalePoint || 0)} ${t('points available')} `}</div>
          </div>
        </div>
        {category.details.length > 0 ? <div className={'pointsCalculator'}>
          <div className={'pointsEarned'}>
            <div className={'earnedText'}>{t("How I earned")}</div>
            {category.details.map((data, index) => (<PointsCard key={index} imageWidth={data.name === 'NEW AMIGOS ADDED' ? '28px' : (data.name === 'WORKOUT MINUTES LOGGED' || data.name === 'FITNESS VIDEOS WATCHED') ? '30px' : '20px'}>
              <div>
                <div>
                  {commaSeperator(data.total || 0)}
                </div>
                <img src={`${ImageUrl}/${data.logo}`} />
              </div>
              <div>
                <div>
                  <div>
                    {data.name}
                  </div>
                  {data.description === "" ? <span onClick={()=>this.redirection(data)}>see more</span>:
                    <div>
                      {data.description}
                    </div>
                  }
                </div>
                <div>
                  {commaSeperator(data.points || 0)}
                  <span>pts</span>
                </div>
              </div>
            </PointsCard>
            ))
            }
          </div>
        </div>
          : null}
        {category.bonus.length > 0 ?
          <div className={'bonusPoints'}>
            <div className={'bonusHeader'}>{t("Bonus Registration Points")}</div>
            {category.bonus.map((data, index) => (<PointsCard key={index}>
              <div>
                <div>
                  {commaSeperator(data.total || 0)}
                </div>
                <img src={`${ImageUrl}/${data.logo}`} />
              </div>
              <div>
                <div>
                  <div>
                    {data.name}
                  </div>
                  <div>
                    {data.description}
                  </div>
                </div>
                <div>
                  {commaSeperator(data.points || 0)}
                  <span>pts</span>
                </div>
              </div>
            </PointsCard>
            ))
            }
          </div> 
          : null}
      </CategoryCardV2>
    );
  }
} 

RewardsCaregory.propTypes = {
  category: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func
}

export default (withTranslation()(RewardsCaregory));